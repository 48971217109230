import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';
import { toast_errors } from 'form_helpers';

// Connects to data-controller="reassign-modal"
export default class extends Controller {
  connect() {
    const backdrop = document.querySelector( '.modal-backdrop' );
    if( backdrop ) {
      backdrop.remove();
    }
    this.modal = new Modal( this.element, { focus: false } );
    this.modal.show();

    // Reset dropdowns when modal is closed
    this.element.addEventListener( 'hidden.bs.modal', () => {
      this.resetDropdowns();
      this.element.remove();
    } );

    // Add click event to submit button to handle dropdown modifications
    this.submitHandler = () => this.prepareFormForSubmission();
    const submitBtn = this.element.querySelector( '[type="submit"]' );
    submitBtn.addEventListener( 'click', this.submitHandler );
  }

  // Disable empty dropdowns to prevent accidental resubmission
  prepareFormForSubmission() {
    this.element.querySelectorAll( '.reassign-labs, .reassign-seats' ).forEach( ( dropdown ) => {
      if ( !dropdown.value ) dropdown.disabled = true; // Disable empty dropdowns
    } );
  }

  submitStart( event ) {
    const formSubmission = event.detail.formSubmission;
    const modalFrame = document.querySelector( 'turbo-frame#reassign-modal' );
    modalFrame.classList.add( 'modal-submitted' );

    if( !this.validateReassignments() ){
      event.preventDefault();
      event.stopImmediatePropagation();
      formSubmission.stop(); // Stop Turbo form submission if validation fails
      this.showError( 'Cannot assign the same group to multiple attendees' );
      this.resetDropdowns();
      return;
    }

    // If validation passes, disable empty dropdowns and the submit button
    const submitBtn = this.element.querySelector( '[type="submit"]' );
    submitBtn.value = submitBtn.dataset.disableText;
    submitBtn.disabled = true;
  }

  // Handle Turbo submit end to check success and close modal if applicable
  submitEnd( event ) {
    const submitBtn = this.element.querySelector( '[type="submit"]' );
    const modalFrame = document.querySelector( 'turbo-frame#reassign-modal' );

    if( event.detail.success && this.isOpen() ){
      this.modal.hide();
    } else {
      // Re-enable the submit button if submission failed
      submitBtn.value = submitBtn.dataset.originalText;
      submitBtn.disabled = false;
    }

    modalFrame.classList.remove( 'modal-submitted' );
  }

  validateReassignments() {
    const reassignInstances = Array.from( this.element.querySelectorAll( '.reassign-labs' ) )
      .map( ( dropdown ) => dropdown.value )
      .filter( Boolean );

    const hasDuplicates = new Set( reassignInstances ).size !== reassignInstances.length;
    return !hasDuplicates; // Return true if no duplicates are found
  }

  showError( message ) {
    toast_errors( message );
  }

  resetDropdowns() {
    this.element.querySelectorAll( '.reassign-labs, .reassign-seats' ).forEach( ( dropdown ) => {
      dropdown.selectedIndex = 0;
      dropdown.disabled = false;
    } );
  }

  isOpen() {
    return this.element.classList.contains( 'show' );
  }

  disconnect() {
    // Remove click event listener from submit button
    const submitBtn = this.element.querySelector( '[type="submit"]' );
    if( submitBtn && this.submitHandler ){
      submitBtn.removeEventListener( 'click', this.submitHandler );
    }

    if( this.modal ){
      this.modal.hide();
    }
  }
}
