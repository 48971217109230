import { Controller } from '@hotwired/stimulus';
import 'datatables.net';
import 'datatables.net-bs5';
import 'datatables.net-buttons';
import 'datatables.net-buttons-bs5';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs5';
import 'datatables.net-rowgroup';
import 'datatables.net-rowgroup-bs5';
import 'datatables.net-select-bs5';
import 'datatables.net-buttons/js/buttons.colVis.min';
import 'datatables.net-buttons/js/buttons.html5.min';
import { dataTablesDefault } from 'datatables_net';

let activeEventUsersControllers = 0;

// Connects to data-controller="event-users"
export default class extends Controller {
  static targets = [ 'table', 'reassignGroupBtn' ];

  connect() {
    $.extend( $.fn.dataTable.ext.classes, {
      container: 'dt-container dt-bootstrap5 h-100',
      length: {
        container: 'dt-length',
        select: 'form-select form-select-solid form-select-sm'
      },
      processing: {
        container: 'dt-processing text-gray-600'
      },
      search: {
        container: 'dt-search',
        input: 'form-control form-control-solid form-control-sm'
      },
      paging: {
        active: 'current',
        button: 'dt-paging-button',
        container: 'dt-paging py-0',
        disabled: 'disabled'
      },
    } );

    const element = this.tableTarget;

    // The data-visible HTML data attributes do not function in datatables-net 2.x.
    // Therefore, the column definition is modified before initialization.
    const columnDefs = [];

    // Collect column visibility settings from the <th> elements.
    $( element ).find( 'thead th' ).each( ( index, th ) => {
      const visible = $( th ).data( 'visible' ) !== false; // Default to true if not specified
      const columnDef = { targets: index, visible: visible };
      columnDefs.push( columnDef );
    } );

    // Create a copy of the default settings and include the columnDefs
    const dataTableSettings = $.extend( {}, dataTablesDefault, {
      columnDefs: columnDefs,
    } );

    const dataTable = $( element ).DataTable( dataTableSettings );

    dataTable.on( 'column-visibility.dt', () => {
      // Update the state of "Select all" button based on column visibility
      const columns = dataTable.columns().visible().toArray();
      const container = $( dataTable.table().container() );
      container.find( '.select-all' ).toggleClass( 'dt-button-active', columns.every( Boolean ) );
      container.find( '.select-all' ).toggleClass( 'indeterminate', !columns.every( Boolean ) && columns.some( Boolean ) );
    } );

    // Increment counter and add the listener if it's the first instance
    if( activeEventUsersControllers === 0 ){
      this.handleTurboStream = this.handleTurboStream.bind( this );
      document.addEventListener( 'turbo:before-stream-render', this.handleTurboStream );
    }
    activeEventUsersControllers += 1;
  }

  disconnect() {
    // Decrement counter and remove listener if this is the last instance
    activeEventUsersControllers -= 1;
    if( activeEventUsersControllers === 0 ){
      document.removeEventListener( 'turbo:before-stream-render', this.handleTurboStream );
    }

    // Destroy the DataTable instance if it exists
    if( $.fn.DataTable.isDataTable( this.tableTarget ) ) {
      $( this.tableTarget ).DataTable().destroy();
    }
  }

  handleTurboStream( event ) {
    const action = event.target.action;
    const stream = event.target;
    const maxInstances = this.data.get( 'maxInstancesValue' );

    if( stream.target.includes( 'event_user' ) ) {
      const dataTable = $( this.tableTarget ).DataTable();
      const row = $( `#${ stream.target }` );
      if( $( this.tableTarget ).hasClass( 'registered-user-table' ) ){
        if( action === 'remove' ){
          if( row.length > 0 ) {
            // Remove the row from the DataTable
            dataTable.row( row ).remove().draw( false );
          }
        } else if( action === 'prepend' ) {
          const template = stream.querySelector( 'template' );
          if( template ){
            // Add the new row to the DataTable
            dataTable.row.add( $( template.innerHTML ) ).draw( false );
          }
        } else if( action === 'replace' ) {
          if( row.length > 0 ){
            const template = stream.querySelector( 'template' );
            // Remove the row from the DataTable
            dataTable.row( row ).remove().draw( false );

            if( template ){
              // Add the new row to the DataTable
              dataTable.row.add( $( template.innerHTML ) ).draw( false );
            }
          }
        }
      }

      // Update event_user_count with the current DataTable row count
      const count = dataTable.rows().count();
      document.querySelector( '#event_user_count' ).textContent = count;

      // Show/hide button based on the condition
      if( this.hasReassignGroupBtnTarget ){
        if( count > 0 && count < maxInstances ){
          this.reassignGroupBtnTarget.classList.remove( 'd-none' );
          this.reassignGroupBtnTarget.classList.add( 'd-flex' );
        } else {
          this.reassignGroupBtnTarget.classList.remove( 'd-flex' );
          this.reassignGroupBtnTarget.classList.add( 'd-none' );
        }
      }

      event.preventDefault();
    }
  }
}
